import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import thumbnailEventWebhook from "../../../static/images/integration/slack-event-webhook.png";
import thumbnailNewsWebhook from "../../../static/images/integration/slack-news-webhook.png";
import thumbnailNoteWebhook from "../../../static/images/integration/slack-note-webhook.png";
import thumbnailGuid1 from "../../../static/images/integration/slack-guid-1.png";
import thumbnailGuid2 from "../../../static/images/integration/slack-guid-2.png";

const AboutPage = () => (
    <Layout>
        <SEO title="API Integration"/>

        <div className={"page-header"}>
            <h2>QRNow API Integration</h2>
            <p>QRNow is excited to announce a new API integration<br />Tt can be really efficient for your business operation.</p>
        </div>

        <div className={"bg-color"}>
            <div className={"container"}>
                <div className={"integration-guid"}>
                    <div className={"title"}>
                        <h2>How to start using QRNow API integration</h2>
                    </div>

                    <div className={"content"}>
                        <h4>Overview</h4>
                        <p>You need to contact us for API integration. With our API integration, you can be in control of qr code and link to your own business system.</p>
                        <h4>Step 1</h4>
                        <p>Company admin needs to enable the integration one time for the whole company to use.</p>

                        <h4>Step 2</h4>
                        <p>Get your token and you can setup integration using our endpoint at https://api.qrnow.org</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default AboutPage
